<style>
  .centered-row {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

<div class="container-fluid">
  <div class="row centered-row">
    <div class="col-md-6">
      <div class="card card-body">
        <h3 class="card-title mt-0">Nous faisons valoir vos droits de consommateur</h3>
        <div class="py-4 border-bottom">
          <div class="d-flex">
            <div class="flex-shrink-0 align-self-center me-3">
              <img src="assets/images/users/avatar-1.jpg" class="avatar-xs rounded-circle" alt="">
            </div>
            <div class="flex-grow-1">
              <h5 class="font-size-15 mb-1">Henry Wells</h5>
              <p class="text-muted mb-0"><i class="mdi mdi-circle text-success align-middle me-1"></i> Votre Assistant
                My claim refunds</p>

            </div>
          </div>

        </div>
        <form [formGroup]="editFormForm">
          <aw-wizard [navBarLayout]="'large-empty-symbols'">

            <aw-wizard-step>

              <br>
              <ngb-alert [dismissible]="false" type="info" role="alert">
                <h5>Bonjour ! Voyons si la compagnie aérienne vous doit une indemnisation. Quelle était votre
                  destination
                  ?</h5>
                <br>
                <div class="row">
                  <div class="col">
                    <label>Au depard</label>
                    <ng-select [items]="filteredAirports" class="custom-ng-select" [loading]="isLoading"
                      placeholder="Aéroport de départ" [bindLabel]="'name'" [bindValue]="'name'" formControlName="departureAirport">

                    </ng-select>

                  </div>
                  <br>
                  <div class="col">
                    <label>Destination finale</label>
                    <ng-select [items]="filteredAirports" class="custom-ng-select" [loading]="isLoading"
                      placeholder="Aéroport de départ" [bindLabel]="'name'" [bindValue]="'name'" formControlName="departureAirport">

                    </ng-select>
                  </div>
                </div>
              </ngb-alert>
              <br>
              <ngb-alert [dismissible]="false" type="info" role="alert">
                <h5>Aviez-vous des correspondances ?</h5>
                <br>
                <div class="row">
                  <div class="col">
                    <div class="control-group" id="toastTypeGroup">
                      <div class="controls mb-4">
                        <label class="card-radio-label mb-2">
                          <input type="radio" name="corresponsances"  [value]="'oui'" formControlName="correspondances" checked id="buycurrencyoption4" class="card-radio-input">
                          <div class="card-radio">
                            <div>

                              <span>Oui</span>
                            </div>
                          </div>
                        </label>

                      </div>

                    </div>
                  </div>
                  <br>
                  <div class="col">
                    <div class="control-group" id="toastTypeGroup">
                      <div class="controls mb-4">
                        <label class="card-radio-label mb-2">
                          <input type="radio" name="corresponsances"  [value]="'non'" formControlName="correspondances" id="buycurrencyoption4" class="card-radio-input">
                          <div class="card-radio">
                            <div>

                              <span>Non</span>
                            </div>
                          </div>
                        </label>

                      </div>

                    </div>
                  </div>
                </div>
              </ngb-alert>
              <ul class="list-inline wizard mb-0">
                <li class="next list-inline-item float-end"><button class="btn btn-primary" awNextStep
                    >Suivant</button>
                </li>
              </ul>
            </aw-wizard-step>

            <aw-wizard-step>
              <ngb-alert type="info" [dismissible]="false" role="alert">
                <h5>Aucun problème ! J’ai juste besoin de quelques détails à propos du vol pour pouvoir vérifier votre
                  éligibilité.</h5>

                <div class="col">
                  <label>Compagnie aérienne</label>
                  <ng-select [items]="company" formControlName="airline" class="custom-ng-select"
                    [loading]="isLoading" [bindLabel]="'name'" [bindValue]="'name'" placeholder="Compagnie aérienne">
                    <!-- <ng-template ng-dropdown-panel let-item="item">
                    <div class="custom-dropdown-panel">
                      {{ item.name }}
                    </div>
                  </ng-template> -->
                  </ng-select>
                </div>
                <label>Numero de vol</label>
                <div class="input-group clockpicker">
                  <input class="form-control" formControlName="flightNumber" placeholder="DR F***">
                </div>
                <label>Date</label>
                <div class="input-group clockpicker">
                  <input ngbDatepicker class="form-control" formControlName="flightDate" placeholder="yyyy-mm-dd"
                    #d="ngbDatepicker">
                  <div class="input-group-append" (click)="d.toggle()">
                    <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                  </div>
                </div>
              </ngb-alert>

              <ul class="list-inline wizard mb-0">
                <li class="previous list-inline-item"><button class="btn btn-primary" awPreviousStep>Retour</button>
                </li>
                <li class="next list-inline-item float-end"><button class="btn btn-primary" awNextStep
                    [disabled]="!isStep2Valid">Suivant</button>
                </li>
              </ul>
            </aw-wizard-step>



            <aw-wizard-step>

              <ngb-alert type="info" [dismissible]="false" role="alert">
                <h5>Passons maintenant à la perturbation du vol. Que s’est-il réellement passé ?</h5>


                <div class="col">
                  <label class="card-radio-label mb-2">
                    <input (change)="deuxiemeSectionVisible = true" type="radio" [value]="'vol retarde'" formControlName="causeVol" id="buycurrencyoption2"
                           class="card-radio-input">
                    <div class="card-radio">
                      <div>
                        <span>Mon vol a été retardé</span>
                      </div>
                    </div>
                  </label>
                </div>
                <label class="card-radio-label mb-2">
                  <input (change)="deuxiemeSectionVisible = true" type="radio" [value]="'vol annulé'" formControlName="causeVol" id="buycurrencyoption2"
                         class="card-radio-input">
                  <div class="card-radio">
                    <div>
                      <span>Mon vol a été annulé</span>
                    </div>
                  </div>
                </label>
                <label class="card-radio-label mb-2">
                  <input (change)="deuxiemeSectionVisible = true" type="radio" [value]="'refus embarquement'" formControlName="causeVol" id="buycurrencyoption3"
                         class="card-radio-input">
                  <div class="card-radio">
                    <div>
                      <span>On m'a refusé l'embarquement</span>
                    </div>
                  </div>
                </label>

              </ngb-alert>

              <ngb-alert type="info" [dismissible]="false" role="alert" *ngIf="deuxiemeSectionVisible">
                <h5>Nous sommes désolés de l’apprendre. Votre arrivée à Yaoundé (NSI) a eu lieu après combien d’heures de retard ?</h5>

                <div class="col">
                  <label class="card-radio-label mb-2">
                    <input (change)="troisiemeSectionVisible = true" [value]="'0-1 heure'" type="radio" formControlName="heuresDeRetard">
                    <div class="card-radio">
                      <div>
                        <span>0-1 heure</span>
                      </div>
                    </div>
                  </label>
                  <label class="card-radio-label mb-2">
                    <input (change)="troisiemeSectionVisible = true" [value]="'1-2 heures'" type="radio" formControlName="heuresDeRetard">
                    <div class="card-radio">
                      <div>
                        <span>1-2 heures</span>
                      </div>
                    </div>
                  </label>
                  <label class="card-radio-label mb-2">
                    <input (change)="troisiemeSectionVisible = true" [value]="'3-4 heures'" type="radio" formControlName="heuresDeRetard">
                    <div class="card-radio">
                      <div>
                        <span>3-4 heures</span>
                      </div>
                    </div>
                  </label>
                  <label class="card-radio-label mb-2">
                    <input (change)="troisiemeSectionVisible = true" [value]="'plus de 4 heures'" type="radio" formControlName="heuresDeRetard">
                    <div class="card-radio">
                      <div>
                        <span>plus de 4 heures</span>
                      </div>
                    </div>
                  </label>
                  <label class="card-radio-label mb-2">
                    <input (change)="troisiemeSectionVisible = true" [value]="'jamais arrivé'" type="radio" formControlName="heuresDeRetard">
                    <div class="card-radio">
                      <div>
                        <span>jamais arrivé</span>
                      </div>
                    </div>
                  </label>
                </div>
              </ngb-alert>
                  <ngb-alert type="info" [dismissible]="false" role="alert" *ngIf="troisiemeSectionVisible">
                <h5>
                  Selon la compagnie aérienne, qu’est-ce qui a causé la perturbation ?</h5>


                <div class="col">
                  <ng-select [items]="selectValueCause" formControlName="cause"></ng-select>
                </div>
              </ngb-alert>

              <ul class="list-inline wizard mb-0">
                <li class="previous list-inline-item"><button class="btn btn-primary" awPreviousStep>Retour</button>
                </li>
                <li class="next list-inline-item float-end"><button class="btn btn-primary" awNextStep
                    [disabled]="!causeSelected">Suivant</button>
                </li>
              </ul>
            </aw-wizard-step>

            <aw-wizard-step>
              <!-- ... autres contenus du formulaire ... -->
              <!-- Partie spécifique du formulaire -->
              <ngb-alert type="info" [dismissible]="false" role="alert">
                <!-- ... contenu de l'alerte ... -->
                <label>Nom</label>
                <div class="input-group clockpicker">
                  <input class="form-control" placeholder="Rochnel" formControlName="nom">
                </div>
                <label>Prenom</label>
                <div class="input-group clockpicker">
                  <input class="form-control" placeholder="Prenom" formControlName="prenom">
                </div>
                <label>Email</label>
                <div class="input-group clockpicker">
                  <input class="form-control" placeholder="Email" formControlName="email">
                </div>
              </ngb-alert>

              <!-- Bouton "Suivant" pour la partie spécifique -->
              <ul class="list-inline wizard mb-0">
                <li class="previous list-inline-item"><button class="btn btn-primary" awPreviousStep>Retour</button>
                </li>
                <li class="next list-inline-item float-end"><button class="btn btn-primary" awNextStep
                    [disabled]="!editFormForm.get('nom').valid || !editFormForm.get('prenom').valid || !editFormForm.get('email').valid">Suivant</button>
                </li>
              </ul>
            </aw-wizard-step>



            <aw-wizard-step>
              <ngb-alert type="info" [dismissible]="false" role="alert">
                <h5>Ajoutez votre adresse.</h5>


                <label>Adresse</label>
                <div class="input-group clockpicker">
                  <input class="form-control" placeholder="Adresse" formControlName="adresse">
                </div>
                <label>Ville</label>
                <div class="input-group clockpicker">
                  <input class="form-control" placeholder="Ville" formControlName="ville">
                </div>
                <label>Code postal</label>
                <div class="input-group clockpicker">
                  <input class="form-control" placeholder="Code postal" formControlName="codePostal">
                </div>
                <label>Région (facultatif)</label>
                <div class="input-group clockpicker">
                  <input class="form-control" placeholder="Région" formControlName="region">
                </div>
                <label>Pays</label>
                <ng-select [items]="contry" [bindLabel]="'name'" [bindValue]="'name'" formControlName="pays"></ng-select>
                <label>Numéro de téléphone</label>
                <div class="input-group clockpicker">
                  <input type="number" class="form-control" placeholder="Numéro de téléphone"
                    formControlName="numeroTelephone">
                </div>
              </ngb-alert>

              <ul class="list-inline wizard mb-0">
                <li class="previous list-inline-item"><button class="btn btn-primary" awPreviousStep>Retour</button>
                </li>
                <li class="next list-inline-item float-end"><button class="btn btn-primary" awNextStep
                    [disabled]="!editFormForm.get('adresse').valid || !editFormForm.get('ville').valid || !editFormForm.get('codePostal').valid || !editFormForm.get('pays').valid">Suivant</button>
                </li>
              </ul>
              <br>
              <ngb-alert type="info" [dismissible]="false" role="alert">
                <h5> <i class="mdi mdi-alert-circle-outline me-2"></i> Une demande faite sans délai peut augmenter vos
                  chances d’obtenir votre indemnisation. Plus de la moitié des premières soumissions sont indemnisées au
                  cours du même mois</h5>
              </ngb-alert>
            </aw-wizard-step>



            <aw-wizard-step>
              <!-- ... autres contenus du formulaire ... -->
              <!-- Partie spécifique du formulaire -->
              <ngb-alert type="info" [dismissible]="false" role="alert">
                <!-- ... contenu de l'alerte ... -->
                <label>Quel est votre numéro de réservation</label>
                <div class="input-group clockpicker">
                  <input class="form-control" placeholder="Numéro de réservation" formControlName="numeroReservation">
                </div>
              </ngb-alert>

              <!-- Bouton "Suivant" pour la partie spécifique -->
              <ul class="list-inline wizard mb-0">
                <li class="previous list-inline-item"><button class="btn btn-primary" awPreviousStep>Retour</button>
                </li>
                <li class="next list-inline-item float-end"><button class="btn btn-primary" awNextStep
                    [disabled]="!editFormForm.get('numeroReservation').valid">Suivant</button></li>
              </ul>

              <br>

              <ngb-alert type="info" [dismissible]="false" role="alert">
                <h5><i class="mdi mdi-alert-circle-outline me-2"></i> Le numéro de réservation est un code unique
                  utilisé par la compagnie aérienne pour garder la trace de chaque réservation. Vous pouvez le retrouver
                  sur votre e-billet ou dans tout e-mail ou document que vous a adressé la compagnie aérienne suite à
                  votre réservation.

                  Ce code est généralement composé de six caractères comportant à la fois des lettres et/ou chiffres (p.
                  ex. DF87G#, REDYYD, L5W4NW). Faites attention à ne pas insérer d’espace</h5>
              </ngb-alert>
            </aw-wizard-step>



            <aw-wizard-step>
              <ngb-alert type="info" [dismissible]="false" role="alert">
                <p>
                  Veuillez fournir une pièce d’identité. Cela permettra à Air France de traiter votre demande
                  d’indemnisation.
                </p>

              </ngb-alert>

              <ngb-alert type="info" [dismissible]="false" role="alert">
                <p>
                  Veuillez télécharger une photo ou une copie scannée de votre carte nationale d’identité (recto verso)
                  ou
                  de votre passeport.
                </p>
                <div class="card">
                  <div class="card-body">
                    <div class="card-title">Pièces d'identité</div>
                    <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)">
                      <ngx-dropzone-label>
                        <div>
                          <h2>selectionner les images de votre pieces d'identité</h2>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                        [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-image-preview>
                    </div>
                  </div>
                </div>

              </ngb-alert>
              <ngb-alert type="info" [dismissible]="false" role="alert">
                <h5>Quels élément doivent figuré sur la photo</h5>
                <p>
                  Assurez-vous que toutes les informations de la pièce d’identité sont clairement visibles, notamment la
                  signature, la photo, la date de naissance, le numéro de document et la date de délivrance.
                  Pour les cartes nationales d’identité, veuillez télécharger une photo du recto et du verso de la
                  carte.
                </p>

              </ngb-alert>

              <ul class="list-inline wizard mb-0">
                <li class="previous list-inline-item"><button class="btn btn-primary" awPreviousStep>Retour</button>
                </li>
                <li class="next list-inline-item float-end"> <button class="btn btn-primary" awNextStep
                    [disabled]="!filesSelected">Suivant</button>
                </li>

              </ul>
            </aw-wizard-step>



            <aw-wizard-step>
              <ngb-alert type="info" [dismissible]="false" role="alert">
                <p>
                  Maintenant, rassemblons les preuves pour votre dossier. Commençons par votre carte d’embarquement ou
                  votre billet électronique, qui sont essentiels pour confirmer les informations relatives à votre vol.
                </p>

              </ngb-alert>

              <ngb-alert type="info" [dismissible]="false" role="alert">
                <p>
                  Veuillez télécharger votre carte d’embarquement ou votre e-billet.
                </p>
                <div class="card">
                  <div class="card-body">
                    <div class="card-title">carte d’embarquement</div>
                    <div>
                      <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelect2($event)">
                        <ngx-dropzone-label>
                          <div>
                            <h2>selectionner les images de votre carte d’embarquement</h2>
                          </div>
                        </ngx-dropzone-label>
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files2"
                          [file]="f" [removable]="true" (removed)="onRemove2(f)">
                          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-image-preview>
                      </div>
                    </div>
                    <div class="text-center mt-4">
                    </div>
                  </div>
                </div>

              </ngb-alert>
              <ngb-alert type="info" [dismissible]="false" role="alert">
                <h5>Qu'es ce qu'une carte d'en barquement</h5>
                <p>
                  La carte d’embarquement est le bordereau que vous recevez après l’enregistrement à l’aéroport, ou un
                  document intégré à l’application si vous vous enregistrez en ligne. Elle vous permet de monter à bord
                  de l’avion et contient des informations comme votre nom et votre numéro de siège.
                  Billet électronique : vous recevez votre billet électronique après avoir effectué votre réservation.
                  Il s’agit d’une confirmation qui vous est envoyée par e-mail et qui contient les noms des passagers,
                  les détails du vol ainsi que le numéro de réservation.
                </p>

              </ngb-alert>

              <ul class="list-inline wizard mb-0">
                <li class="previous list-inline-item"><button class="btn btn-primary" awPreviousStep>Retour</button>
                </li>
                <li class="next list-inline-item float-end"><button class="btn btn-primary" awNextStep
                    [disabled]="!filesSelected2">Suivant</button>
                </li>
              </ul>
            </aw-wizard-step>

            <aw-wizard-step>
              <br>
              <ngb-alert [dismissible]="false" type="info" role="alert">
                <h5>Avez-vous déjà contacté la compagnie aérienne ?</h5>
                <br>
                <div class="col">
                  <div class="col">
                    <div class="control-group" id="toastTypeGroup">
                      <div class="controls mb-4">
                        <label class="card-radio-label mb-2">
                          <input type="radio" name="currency5" [value]="'non'" formControlName="contacterCompagnie" checked id="buycurrencyoption4" class="card-radio-input">
                          <div class="card-radio">
                            <div>

                              <span>Non</span>
                            </div>
                          </div>
                        </label>

                      </div>

                    </div>
                  </div>
                  <br>
                  <div class="col">
                    <div class="control-group" id="toastTypeGroup">
                      <div class="controls mb-4">
                        <label class="card-radio-label mb-2">
                          <input type="radio" name="currency5" [value]="'oui'"  formControlName="contacterCompagnie" id="buycurrencyoption4" class="card-radio-input">
                          <div class="card-radio">
                            <div>

                              <span>Oui</span>
                            </div>
                          </div>
                        </label>

                      </div>

                    </div>
                  </div>
                </div>
              </ngb-alert>
              <br>
              <ngb-alert [dismissible]="false" type="info" role="alert">
                <h5>Veuillez ajouter d’autres informations concernant votre vol annulation </h5>
                <br>
                <div class="input-group clockpicker">
                  <textarea formControlName="description" class="form-control" rows="5"></textarea>
                </div>
              </ngb-alert>
              <ul class="list-inline wizard mb-0">
                <li class="previous list-inline-item"><button class="btn btn-primary" awPreviousStep>Retour</button>
                </li>
                <li class="next list-inline-item float-end"><button class="btn btn-primary" awNextStep
                    [disabled]="!editFormForm.get('description').valid " (click)="save()">Terminer</button>
                </li>
              </ul>
            </aw-wizard-step>


            <aw-wizard-step>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <table class="body-wrap"
                      style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; background-color: transparent; margin: 0;">
                      <tr
                        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                        <td
                          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;"
                          valign="top"></td>
                        <td class="container" width="600"
                          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; display: block !important; max-width: 600px !important; clear: both !important; margin: 0 auto;"
                          valign="top">
                          <div class="content"
                            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; max-width: 600px; display: block; margin: 0 auto; padding: 20px;">
                            <table class="main" width="100%" cellpadding="0" cellspacing="0"
                              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; border-radius: 7px; background-color: #fff; color: #495057; margin: 0; box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);"
                              bgcolor="#fff">
                              <tr
                                style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                <td class="alert alert-warning"
                                  style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 16px; vertical-align: top; color: #fff; font-weight: 500; text-align: center; border-radius: 7px 7px 0 0; background-color: #556ee6; margin: 0; padding: 20px;"
                                  align="center" bgcolor="#71b6f9" valign="top">
                                  Air Help
                                </td>
                              </tr>
                              <tr
                                style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                <td class="content-wrap"
                                  style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 20px;"
                                  valign="top">
                                  <table width="100%" cellpadding="0" cellspacing="0"
                                    style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">

                                    <tr
                                      style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                      <td class="content-block"
                                        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                                        valign="top">
                                        Merci, votre demande d’indemnisation a été transmise.
                                      </td>
                                    </tr>
                                    <tr
                                      style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                      <td class="content-block"
                                        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                                        valign="top">
                                        <a href="https://wa.me/+33615986592?text=Votre%20message%20ici"
                                          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #34c38f; margin: 0; border-color: #34c38f; border-style: solid; border-width: 8px 16px;">
                                          Verifier le status de ma demande</a>
                                      </td>
                                    </tr>
                                    <tr
                                      style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                      <td class="content-block"
                                        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                                        valign="top">
                                        Merci d'avoir choisir My Claim Refund <b></b> Admin.
                                      </td>
                                    </tr>
                                    <tr
                                      style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                      <td class="content-block"
                                        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                                        valign="top">
                                        <b></b>
                                        <p>Support Team</p>
                                      </td>
                                    </tr>

                                  </table>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <!-- end table -->
                  </div>
                </div>
                <!-- end row -->
              </div>


            </aw-wizard-step>

          </aw-wizard>

        </form>

        <br>
        <ngb-alert type="info" [dismissible]="false" role="alert">
          <h5> <i class="mdi mdi-alert-circle-outline me-2"></i> Aucun risque. Le contrôle de l’indemnisation est
            absolument <b>gratuit</b>.</h5>
        </ngb-alert>
      </div>
    </div>
  </div>
</div>
