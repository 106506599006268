import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimTripDetailService {
  private endpoint = 'https://api.flightstats.com/flex/schedules/rest/v2/schedules';

  constructor(private httpClient: HttpClient) { }

  getFlights(): Observable<any> {
    const params = new HttpParams()
      .set('origin', 'LHR')
      .set('destination', 'JFK')
      .set('departureDate', '2023-09-30');

    return this.httpClient.get(this.endpoint, { params });
  }
}
