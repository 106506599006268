<nav class="navbar navbar-expand-lg navigation fixed-top sticky" (window:scroll)="windowScroll()" id="navbar">
    <div class="container">
        <a class="navbar-logo" routerLink="/">
            <img src="../../../../assets/images/capture.jpg" alt="" height="19" class="logo logo-dark">
            <img src="../../../../assets/images/capture.jpg" alt="" height="19" class="logo logo-light">
        </a>

        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" data-toggle="collapse"
            data-target="#topnav-menu-content" (click)="toggleMenu()">
            <i class="fa fa-fw fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="topnav-menu-content">

            <div class="my-2 ms-lg-2">
                <a href="javascript: void(0);" class="btn btn-outline-success w-xs">Comment ca marche?</a>
            </div>
            <div class="my-2 ms-lg-2">
                <a href="javascript: void(0);" class="btn btn-outline-success w-xs">Questions/Reponses</a>
            </div>
            <div class="my-2 ms-lg-2">
                <a href="javascript: void(0);" class="btn btn-outline-success w-xs" [routerLink]="'claim'">Soumettre ma
                    reclamation</a>
            </div>
            <div class="my-2 ms-lg-2">
                <a href="javascript: void(0);" class="btn btn-outline-success w-xs"
                    (click)="ouvrirConversationWhatsApp()">Statut de ma reclamation</a>
            </div>
            <div class="my-2 ms-lg-2">
                <a href="https://fb.me/myclaimrefunds" class="btn btn-outline-success w-xs">Suivre ma reclamation</a>
            </div>
        </div>
    </div>
</nav>

<div appScrollspy (sectionChange)="onSectionChange($event)">
    <!-- hero section start -->
    <section class="section hero-section bg-ico-hero" id="home">
        <h3 style="text-align: center;">Le justicier de vos droits de passagers de l'aerien.</h3>
        <ngb-carousel>
            <ng-template ngbSlide>
                <img src="../../../../assets/images/bg1.jpg" alt="first slide" class="d-block img-fluid w-100">

            </ng-template>
            <ng-template ngbSlide>
                <img src="../../../../assets/images/bg2.jpg" alt="second slide" class="d-block img-fluid w-100">

            </ng-template>
            <ng-template ngbSlide>
                <img src="../../../../assets/images/bg3.jpg" alt="third slide" class="d-block img-fluid w-100">

            </ng-template>
        </ngb-carousel>

        <!-- end container -->
    </section>
    <!-- hero section end -->



    <!-- about section start -->
    <section class="section pt-4 bg-white" id="about">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">A propos de nous</div>
                        <h4>c'est quoi MYCLAIMSREFUNDS.COM?</h4>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-5">

                    <div class="text-muted">
                        <h4>Votre vol est retardé ou annulé, <br> Votre justicer obtient pour vous jusqu'à 600£ par
                            passager.</h4>
                        <p>Notre equipe de juristes et d'Avocats spécialisés dans le droit Aerien s'occupent
                            gratuitement des procédures auprèes des <br>compagnies aeriennes. Nous sommes rénumérés
                            uniquement en cas d'obtrntion de l'indeminisation auprés de la compagnie aerienne.</p>

                    </div>
                </div>

                <div class="col-lg-6 ms-auto">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="card border">
                                <div class="card-body">
                                    <img src="../../../../assets/images/bg3.jpg" width="300px" height="300px"
                                        class="shadow-1-strong rounded" alt="Mountains in the Clouds" />

                                </div>

                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card border mt-lg-5">
                                <div class="card-body">
                                    <img src="../../../../assets/images/bg4.jpg" width="300px" height="300px"
                                        class="shadow-1-strong rounded" alt="Mountains in the Clouds" />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <hr class="my-5">

            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- about section end -->

    <!-- Features start -->
    <section class="section" id="features">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title"></div>
                        <h4>Comment ca marche?</h4>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row align-items-center pt-4">
                <div class="col-md-6 col-sm-8">
                    <div>
                        <img src="../../../../assets/images/bg6.jpg" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
                <div class="col-md-5 ms-auto">
                    <div class="mt-4 mt-md-auto">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">01</div>
                            <h4 class="mb-0">Communiquez avec nous</h4>
                        </div>

                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row align-items-center mt-5 pt-md-5">
                <div class="col-md-5">
                    <div class="mt-4 mt-md-0">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">02</div>
                            <h4 class="mb-0">Nos Juristes et avocats verifent l'éligibilité de votre vol</h4>
                        </div>

                    </div>
                </div>
                <div class="col-md-6  col-sm-8 ms-md-auto">
                    <div class="mt-4 me-md-0">
                        <img src="../../../../assets/images/bg7.jpg" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>

            </div>
            <!-- end row -->

            <div class="row align-items-center pt-4">
                <div class="col-md-6 col-sm-8">
                    <div>
                        <img src="../../../../assets/images/bg6.jpg" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
                <div class="col-md-5 ms-auto">
                    <div class="mt-4 mt-md-auto">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">03</div>
                            <h4 class="mb-0">Notre équipe contacte la compagnie aérienne par votre légale</h4>
                        </div>

                    </div>
                </div>
            </div>
            <!-- end row -->
            <div class="row align-items-center mt-5 pt-md-5">
                <div class="col-md-5">
                    <div class="mt-4 mt-md-0">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">04</div>
                            <h4 class="mb-0">Nous obtenons l'indemnisation</h4>
                        </div>

                    </div>
                </div>
                <div class="col-md-6  col-sm-8 ms-md-auto">
                    <div class="mt-4 me-md-0">
                        <img src="../../../../assets/images/bg7.jpg" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>

            </div>
            <!-- end row -->
            <div class="row align-items-center pt-4">
                <div class="col-md-6 col-sm-8">
                    <div>
                        <img src="../../../../assets/images/bg6.jpg" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
                <div class="col-md-5 ms-auto">
                    <div class="mt-4 mt-md-auto">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">05</div>
                            <h4 class="mb-0">Nous recevez votre indemnisation 72h plus tard</h4>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- end container -->
    </section>
    <!-- Features end -->



    <!-- Faqs start -->
    <section class="section" id="faqs">
        <div class="container">

            <!-- end row -->

            <div class="row">
                <div class="col-lg-12">
                    <div class="vertical-nav">
                        <div class="row">
                            <div class="col-lg-2 col-sm-4">
                                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-pills flex-column">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink>
                                            <i class="bx bx-timer d-block nav-icon mb-2"></i>
                                            <p class="fw-bold mb-0">Question / Reponses</p>
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="card">
                                                <div class="card-body">
                                                    <h4 class="card-title mb-4">Question / Reponses</h4>

                                                    <ngb-accordion [closeOthers]="true" activeIds="static-1"
                                                        class="custom-accordion">
                                                        <ngb-panel id="static-1">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Nous avons déjà soumis une réclamation à la
                                                                        compagnie aérienne, et elle a rejeté.
                                                                        Pouvez-vous aider ?
                                                                    </div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <div class="card-body p-0">
                                                                    <p class="mb-0">Il s'agit d'une situation normale,
                                                                        puisque les compagnies aériennes n'acceptent
                                                                        presque jamais de payer en une seule fois. Notre
                                                                        expérience de travail avec les compagnies
                                                                        aériennes nous permet de maximiser les chances
                                                                        d’obtenir une indemnisation devant les
                                                                        tribunaux. Si la compagnie aérienne refuse
                                                                        toujours de payer, nous pouvons faire appel à
                                                                        des tiers pour préparer la procédure judiciaire
                                                                        afin de garantir une résolution réussie de
                                                                        l'affaire.
                                                                    </p>
                                                                </div>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-2">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Dois-je être citoyen de l’UE pour avoir droit à
                                                                        l’indemnisation ?
                                                                    </div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <div class="card-body p-0">
                                                                    <p class="mb-0">Non. Vous pouvez demander votre
                                                                        indemnisation sans détenir la citoyenneté
                                                                        européenne, ce qui n’affecte pas la mise en
                                                                        œuvre du règlement.
                                                                    </p>
                                                                </div>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-3">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Comment Myclaimrefunds peut-il m'aider ?
                                                                    </div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <div class="card-body p-0">
                                                                    <p class="mb-0">Analyse détaillée de toutes les
                                                                        circonstances du cas avec l'utilisation de notre
                                                                        logiciel
                                                                        Rédiger tous les documents nécessaires et les
                                                                        transmettre aux parties
                                                                        Négociations avec la compagnie aérienne
                                                                        Si nécessaire (nous impliquerons nos partenaires
                                                                        et prendrons tous les coûts et risques) :
                                                                        <br>
                                                                        <br>
                                                                        Préparation au tribunal

                                                                        Vous êtes toujours au courant du statut de votre
                                                                        candidature via votre profil personnel et nos
                                                                        notifications.
                                                                    </p>
                                                                </div>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-4">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Combien coûtera l’indemnisation ?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <div class="card-body p-0">
                                                                    <p class="mb-0">Nous vous fournissons gratuitement
                                                                        tous les services nécessaires. Nous facturons
                                                                        uniquement des frais de réussite conformément à
                                                                        la liste de prix. Nous facturons ces frais
                                                                        uniquement après avoir reçu une compensation.
                                                                        .</p>
                                                                </div>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-5">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Combien de temps faut-il pour obtenir une
                                                                        indemnisation ?
                                                                    </div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <div class="card-body p-0">
                                                                    <p class="mb-0">Nous essayons de vous obtenir votre
                                                                        indemnisation dans les plus brefs délais.
                                                                        Certaines compagnies aériennes répondent dans un
                                                                        délai de 2 à 3 jours, d'autres dans un délai de
                                                                        4 à 6 semaines. En moyenne, nous garantissons
                                                                        l'acceptation préalable au tribunal dans les 8
                                                                        semaines suivant le dépôt de la réclamation. Si
                                                                        une procédure judiciaire est impliquée, la durée
                                                                        peut atteindre 16 à 20 semaines.
                                                                    </p>
                                                                </div>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-6">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Que se passe-t-il si l'interruption de mon vol
                                                                        s'est produite il y a longtemps ?

                                                                    </div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <div class="card-body p-0">
                                                                    <p class="mb-0">Si votre vol a été retardé ou
                                                                        annulé, ou si l'embarquement vous a été refusé
                                                                        au cours des 2 à 6 dernières années (en fonction
                                                                        de la compagnie aérienne opérationnelle et de
                                                                        votre itinéraire), vous avez droit à une
                                                                        indemnisation et pouvez soumettre une demande à
                                                                        Compensair.

                                                                    </p>
                                                                </div>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-7">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Qui recevra l'indemnisation si le billet a été
                                                                        acheté par l'entreprise pour laquelle je
                                                                        travaille ?

                                                                    </div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <div class="card-body p-0">
                                                                    <p class="mb-0">Seul le passager a droit à une
                                                                        indemnisation, quel que soit celui qui a payé le
                                                                        billet.

                                                                    </p>
                                                                </div>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-8">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Que dois-je fournir pour demander une
                                                                        indemnisation ?

                                                                    </div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <div class="card-body p-0">
                                                                    <p class="mb-0">Informations sur le vol et le numéro
                                                                        de réservation ou de billet
                                                                    </p>
                                                                </div>
                                                            </ng-template>
                                                        </ngb-panel>
                                                    </ngb-accordion>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2">
                                        <a ngbNavLink>
                                            <i class="bx bx-help-circle nav-icon d-block mb-2"></i>
                                            <p class="fw-bold mb-0">Termes et conditions</p>
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="card">
                                                <div class="card-body">
                                                    <h4 class="card-title mb-4">Termes et conditions</h4>
                                                    <pre>
1. Définitions

1.1. « Candidature » – un formulaire rempli par le Client sur le site compensair.com, qui contient les Données Personnelles du Client.

1.2. « Cas » : le litige du passager concernant une compensation monétaire résultant de l'annulation d'un vol, d'un retard ou d'autres circonstances causées par la compagnie aérienne.

1.3. « Client » – le passager qui conclut un accord avec Compensair.

1.4. « Compensation » – le montant d'argent qu'un passager a le droit de réclamer à une compagnie aérienne en cas de retard ou d'annulation de vol.

1.5. « Partenaire » – une personne physique ou morale qui a noué des relations contractuelles avec nous dans le but de fournir des services, du marketing ou une campagne d'information sur le service Compensair.

1.6. « Services » : accès à des informations, services de conseil, y compris, mais sans s'y limiter, la fourniture d'informations sur les vols, les informations sur les compagnies aériennes, les informations sur les aéroports, d'autres informations relatives aux voyages, des informations sur les droits des passagers aériens, les lois sur la protection des consommateurs et d'autres services fournis par nos partenaires, tels que en tant que services financiers intermédiaires et autres services sur lesquels les Parties peuvent convenir.

1.7. « Frais de service » – le montant de la compensation convenu conformément à la politique de paiement.

La politique de paiement, la politique de confidentialité et la politique en matière de cookies font partie intégrante des conditions générales.

2. Conditions générales

2.1. En acceptant les présentes Conditions générales, le Client accepte toutes les dispositions des Conditions générales ainsi que les dispositions de la Politique de confidentialité, de la Politique en matière de cookies et de la Politique de paiement, accessibles sur le Site Web.

2.2. Parallèlement aux relations contractuelles avec nous, le Client peut participer au marketing ou à nos programmes de bonus. Dans ce cas, les dispositions contractuelles pertinentes de Compensair avec des tiers, partenaires de ces programmes, deviennent partie intégrante du présent Accord. En participant à des programmes de marketing ou de bonus, le Client donne à Compensair le droit de partager ses données personnelles avec des tiers – fournisseurs de ces programmes.

2.3. En concluant le Contrat avec nous, le Client déclare qu'il a le droit de conclure le présent Contrat en son nom ou au nom du (des autres) demandeur(s) indiqué(s) par lui et qu'il a la capacité juridique de le faire. En cas de violation d'un mandat par le client, Myclaimrefunds a le droit de résilier le contrat unilatéralement avec la notification du client.

2.4. En acceptant les conditions générales, le client accepte par la présente de nous engager, ainsi que ses partenaires, à fournir les services au client.

2.5. Le Client a le droit de résilier le Contrat dans un délai de 7 (sept) jours à compter de la date de sa conclusion, sauf si nous envoyons les documents relatifs à la compagnie aérienne dans ce délai de 7 (sept) jours.

2.6. Le Client garantit qu'à ce stade, l'indemnisation n'est pas recherchée par lui personnellement ou par un tiers et qu'aucun litige juridique n'est en cours entre le Client et la compagnie aérienne sur le même sujet. En cas de violation de la garantie par le Client, il assume toutes les responsabilités, y compris l'obligation de payer les frais de justice et juridiques liés à sa réclamation.
2.7. Après avoir conclu le Contrat, le Client accorde à Compensair et à ses partenaires le droit de récupérer une Indemnisation au profit du Client, confie à Compensair et à ses partenaires de recevoir une Indemnisation sur notre solde ou notre compte bancaire et s'engage à ne pas poursuivre la réclamation sur son posséder et engager tout litige ou procédure ADR, ainsi que le céder à toute autre partie sans notre consentement. En cas de violation de la garantie par le client et de réception d'une indemnisation ou d'un autre type de rémunération, y compris, mais sans s'y limiter, un bon/billet gratuit de la compagnie aérienne ou de tiers après avoir conclu l'accord avec nous, le client est tenu de transférer le service convenu. Frais sur notre compte bancaire.

2.8. Après l'acceptation des Conditions Générales, le Client sera tenu de nous transmettre tous les enregistrements de correspondance concernant le Cas (le cas échéant) et de cesser toute négociation avec la compagnie aérienne concernée (le cas échéant) et de nous diriger tout contact établi par la compagnie aérienne. .

2.9. Si le Client reçoit des paiements directs ou une Compensation/Bon/Billet gratuit ou tout autre type de rémunération de la part de la compagnie aérienne ou de tiers concernant le Cas après la conclusion du Contrat, le Client sera tenu de nous en informer sans délai et de transférer le Service convenu. Frais sur notre compte bancaire.

2.10. Le Client est tenu de nous fournir les informations complètes, véridiques et exactes nécessaires à la poursuite du Dossier pendant la durée du Contrat.

2.11. Le Contrat prend fin lorsque :
  2.11.1. La compensation est récupérée avec succès et transférée au client avec déduction des frais de service établis.

2.11.2. Mycmaimrefunds a établi qu'il serait vain de poursuivre le dossier après avoir procédé à un examen approfondi du dossier et a conseillé au client d'arrêter la poursuite des travaux.

3. Droits et devoirs

3.1. Myclaimrefunds assistera le Client dans les négociations avec la compagnie aérienne pour le recouvrement de l’Indemnisation, conformément à la loi applicable au voyage aérien particulier du Client.

3.2. Myclaimrefunds informera le Client des principales étapes du règlement des litiges.

3.3. Myclaimrefunds ne fournit au Client aucune copie de la correspondance avec des tiers.

3.4. Si la compensation est collectée avec succès, la somme sera transférée du solde vers le compte bancaire personnel avec déduction des frais de service convenus (le montant des frais est convenu conformément à notre politique de paiement ou aux conditions du programme du partenaire).

3.5. Le client reconnaît qu'après avoir rempli la demande, il nous transfère le droit de toute prise de décision en matière de résolution des litiges.

3.6. Myclaimrefunds engagera des tiers pour prendre la décision d’engager ou non une procédure judiciaire pour le recouvrement de l’indemnisation. C'est notre seule décision et celle des tiers engagés d'intenter une action devant le tribunal. Le Client sera informé d'une telle décision au plus tard 7 (sept) jours avant le début de la procédure.

3.7. En cas de procédure judiciaire, un représentant légal sous contrat sera utilisé pour l'action en justice, le Client nous permet d'accorder au représentant légal sous contrat l'accès à toutes les données qui nous sont communiquées et permet au représentant légal de nous transférer des informations concernant la procédure. . Lorsque d’autres documents complémentaires sont requis pour l’action en justice, le Client s’engage à fournir ces documents complémentaires.

3.8. Le Client est autorisé à retirer à tout moment les instructions d'ouverture d'une procédure judiciaire. Dans ce cas, le Client sera tenu de rembourser tous les frais de justice supportés par nous ou nos partenaires.

4. Modalités de rémunération

4.1. Le client ne nous paiera qu'en cas de collecte réussie de l'indemnisation du vol.

4.2. Tous les fonds collectés auprès de la Compagnie aérienne et de tiers après la conclusion du Contrat et relatifs au cas du Client seront considérés comme ayant été collectés grâce aux efforts et à nos activités et à ceux de nos partenaires.

4.3. Le Client reconnaît que les bons touristiques ou les offres de tout autre service ne pourront être acceptés à titre de Rémunération. L'offre de bon d'achat ou d'autres alternatives doit être considérée comme un refus d'indemnisation.

4.4. Le Client reconnaît devoir considérer le paiement d’une indemnisation partielle comme le refus de l’Indemnisation. C'est notre seule décision, ainsi que celle de nos partenaires, d'accepter ou de refuser le paiement d'une indemnisation partielle. Compensair prend la décision appropriée après un examen attentif de toutes les circonstances du cas.

Dans le cas où Compensair accepte le paiement d’une indemnisation partielle, le client est assuré de recevoir le montant approprié de l’indemnisation nette de frais.
Dans le cas où Myclaimrefunds accepte le paiement d’une indemnisation partielle, le client est assuré de recevoir le montant approprié de l’indemnisation nette de frais.

4.5. Dans le cas où l'accord avec nous est conclu via un partenaire ou un autre accord d'entreprise, la structure des frais, les options de paiement, la conversion de devises et autres peuvent être différentes en fonction du niveau spécifique de services fournis et des conditions individuelles convenues.

5. Dispositions finales

5.1. Compensair est autorisée à modifier les Conditions générales et à énoncer des conditions supplémentaires à tout moment et sans préavis, à l'exception des modifications ayant un impact négatif sur le Client.

5.2. Myclaimrefunds précise les délais de traitement de la demande et de la réclamation par défaut. Les délais d'examen d'une réclamation par la compagnie aérienne, ainsi que par les autorités extrajudiciaires et judiciaires, sont relatifs et ne dépendent pas de nous.

5.3. Les lois de Singapour s'appliquent aux Conditions générales, à l'Accord et/ou à tout autre document conclu en relation avec les Conditions générales et l'Accord, sauf accord contraire dans le document spécifique qui nous satisfait.

5.4. Tout litige, controverse ou réclamation, découlant de ou lié aux Conditions générales, à leur violation, à leur résiliation ou à leur validité, sera définitivement réglé devant le tribunal respectif de Singapour, sous réserve des règles de compétence.

5.5. Si une disposition des Conditions générales est jugée illégale, invalide ou inapplicable par un tribunal ou un tribunal arbitral, les autres dispositions des Conditions générales resteront pleinement en vigueur.
                                                    </pre>

                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="3">
                                        <a ngbNavLink>
                                            <i class="bx bx-receipt nav-icon d-block mb-2"></i>
                                            <p class="fw-bold mb-0">Polique de confidentialité</p>
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="card">
                                                <div class="card-body">
                                                    <h4 class="card-title mb-4">Polique de confidentialité</h4>
                                                    <pre>

Notre politique de confidentialité doit être lue conjointement avec nos conditions générales, où vous trouverez tous les termes définis utilisés dans notre politique de confidentialité.

Compensair respecte la vie privée de tous les visiteurs et clients du site Web et s'engage à assurer la sécurité de leurs données personnelles tant lors de la visite du site Web que lors de l'utilisation des services que nous fournissons.

En utilisant le site Web et les services que nous fournissons ainsi qu'en nous fournissant des informations personnelles, vous acceptez par la présente les dispositions de cette politique de confidentialité. Veuillez lire attentivement ce qui suit pour comprendre nos points de vue et nos pratiques concernant vos données personnelles et la manière dont nous les traiterons.

Veuillez transmettre toute demande de renseignements que vous pourriez avoir concernant cette politique de confidentialité à l'e-mail suivant support@myclaimrefunds.com.

1. Données personnelles collectées et traitées et finalités de leur collecte et de leur traitement

1.1. Myclaimrefunds est un service qui poursuit les objectifs suivants :

1.1.1. Fournir les Services conformément aux « Conditions générales » ;

1.1.2. Informer le Client sur la protection des droits des passagers et d’autres questions liées à l’exercice des droits des passagers.

1.1.3. Informer le Client des campagnes publicitaires et des offres de nous et de nos Partenaires.

1.2. Lors de la collecte et du traitement des données personnelles, nous agissons conformément à la législation de Singapour et au Règlement général sur la protection des données (UE) (2016/679), y compris les principes consacrés à l'article 5 du RGPD :

1.2.1. Le traitement des données personnelles doit avoir lieu de manière licite, équitable et transparente ;

1.2.2. La collecte de données personnelles ne sera effectuée qu'à des fins déterminées, explicites et légitimes et ne sera pas traitée ultérieurement d'une manière incompatible avec ces finalités ;

1.2.3. La collecte des données personnelles doit être adéquate, pertinente et limitée à ce qui est nécessaire au regard de la finalité pour laquelle elles sont traitées ;

1.2.4. Les données personnelles doivent être exactes et, si nécessaire, tenues à jour ;

1.2.5. Toutes les mesures raisonnables seront prises pour garantir que les données personnelles inexactes au regard des finalités pour lesquelles elles sont traitées soient effacées ou rectifiées sans délai ;

1.2.6. Les données personnelles sont conservées sous une forme permettant l'identification de la personne concernée pendant la durée nécessaire à la finalité pour laquelle les données personnelles sont traitées ;

1.2.7. Toutes les données personnelles doivent rester confidentielles et stockées de manière à garantir une sécurité appropriée ;

1.2.8. Les données personnelles ne seront pas partagées avec des tiers, sauf lorsque cela est nécessaire pour qu'ils fournissent des services après accord ;

1.2.9. Les personnes concernées ont le droit de demander l'accès aux données personnelles, leur rectification ou leur effacement, ou la limitation du traitement, ou de s'opposer au traitement ainsi que le droit à la portabilité des données.

1.3. Pour récupérer une indemnisation avec l'aide de Compensair, le Client fait une demande. En remplissant une candidature, le client fournit les données personnelles suivantes : nom et prénom, e-mail, numéro de téléphone portable, adresse, date de naissance. Ces données personnelles sont demandées car les compagnies aériennes refusent d'examiner un dossier sans elles.

Dans le cas où le Client a voyagé avec un mineur dont le représentant légal est le Client lors du dépôt de la demande, le Client mentionne également le nom et le prénom du mineur ainsi que sa date de naissance.

Dans les champs « Raison suggérée du retard » et « Description », le Client peut fournir une brève description du cas et des détails supplémentaires. Ces champs ne sont pas destinés aux données personnelles du Client.

A l'issue du dépôt de sa demande, il est proposé au Client de mentionner un numéro de vol et un numéro de réservation. Il est également proposé au Client de télécharger un billet et une confirmation de réservation. Les données personnelles susmentionnées sont utilisées uniquement aux fins indiquées au sous-paragraphe 1.1. de la Politique de Confidentialité.

1.4. Après le dépôt d'une candidature, un compte personnel pour chaque client est créé dans notre système où toutes les données téléchargées par le client sont stockées. Le Client peut à tout moment ajouter lui-même une description ou télécharger des documents complémentaires.
Les autres droits du Client liés aux données personnelles, tels qu'un droit de rectification ou un droit à l'effacement, sont énoncés au paragraphe 4 de la Politique de Confidentialité.

1.5. Des données personnelles supplémentaires du Client telles qu'une carte d'identité du Client (ID) utilisée pour acheter un billet, une procuration signée à la main du Client peuvent être demandées par la compagnie aérienne ou toute autre autorité compétente. Dans ce cas Compensair propose au Client de télécharger les documents mentionnés via son compte personnel. Après le téléchargement, les documents du client sont stockés dans le système interne de Compensair. Pour des raisons de sécurité, seules les informations sur le nombre de documents téléchargés sont affichées sur le compte personnel du Client. Les fichiers des documents eux-mêmes ne sont pas affichés et ne sont pas disponibles au téléchargement.
1.6. Si la décision de payer une indemnisation est prise, la liste des données personnelles du Client qui sont demandées, les finalités de leur utilisation, l’ordre de leur traitement et de leur stockage sont précisés par notre Politique de paiement.

1.7. En plus des données mentionnées ci-dessus, nous collectons et traitons la correspondance entre nous et le Client.

1.8. Si le Client laisse ses commentaires sur le site Internet ou sur les communautés officielles des réseaux sociaux.

1.9. Compensair peut collecter des informations en plaçant des cookies sur l’ordinateur du client ou sur tout autre appareil que le client utilise pour accéder au site Web. Les informations sur les cookies et les finalités de leur utilisation sont fournies par notre Politique en matière de cookies.

2. La durée de conservation des données personnelles

2.1. Les données personnelles du Client sont conservées pendant 10 (dix) ans à compter de l'exécution du contrat entre Compensair et le Client ou depuis la dernière action du Client sur le site Internet si le Client n'a pas finalisé une demande d'indemnisation.

2.2. Les données liées à l'utilisation par le Client des opportunités ou des services fournis par notre site Internet et qui ne sont pas considérées comme des données personnelles, pourront être traitées de manière anonyme lors d'études statistiques et marketing ou de toute autre activité similaire. En tout état de cause, un tel traitement de données ne permettra pas d'identifier directement ou indirectement le Client.

3. Divulgation d'informations

3.1. Les données du Client peuvent être divulguées à nos Partenaires, à ses Affiliés et à d’autres tiers si cela est nécessaire aux fins de perception d’une indemnisation pour la réclamation du Client ainsi que pour l’exécution de nos obligations contractuelles. Myclaimrefunds ne divulgue pas les données à des tiers pour leur donner la possibilité de faire de la publicité pour leurs produits ou services auprès du client.

3.2. Myclaimrefunds peut divulguer les données du Client si cela est requis par la loi (par exemple, par les autorités de contrôle pour répondre aux exigences du RGPD) ; pour se conformer à une assignation à comparaître ou à d'autres procédures juridiques, lorsque Myclaimrefunds estime de bonne foi que la divulgation est nécessaire pour protéger les droits de Compensair, pour protéger la sécurité des clients ou d'autrui.

4. Droits du client

4.1. Le Client a le droit de déposer une plainte auprès des régulateurs de la protection des données. Myclaimrefunds est enregistré en Europe et la Commission de protection des données personnelles (PDPC) est notre principal régulateur. Le Client peut découvrir comment faire part d'une préoccupation auprès du PDPC en visitant son site Web à l'adresse ​www.pdpc.gov.sg.​ Si le Client se trouve dans l'UE, il peut également contacter son régulateur local de la protection des données.

4.2. Le Client dispose d'un certain nombre de droits en vertu de la loi européenne sur la protection des données s'il est citoyen de l'UE, de la Suisse ou du Royaume-Uni :

4.2.1. Droit de retirer votre consentement au traitement des données personnelles ;

4.2.2. Droit d’être informé : quelles données personnelles Compensair traite et pourquoi. Les informations sont envoyées au Client à l’adresse email fournie dans un délai de 1 (un) mois à compter de la date de la demande du Client. Le délai de réponse peut être prolongé jusqu'à 2 (deux) mois en raison du nombre et de la complexité des demandes ;

4.2.3. Droit d'accès : le Client peut demander une copie de ses données, mais ce droit n'inclut pas l'accès à la correspondance interne de Compensair, y compris la correspondance avec des tiers examinant le cas du Client ;

4.2.4. Droit de rectification : si les données détenues sont inexactes, le Client a le droit de les faire rectifier ;

4.2.5. Droit à l'effacement (« droit à l'oubli ») : le Client a le droit de faire supprimer ses données dans certaines circonstances ;

4.2.6. Droit à la limitation du traitement : dans des circonstances limitées, le Client a le droit de demander que le traitement soit arrêté mais que les données soient conservées ;

4.2.7. Droit à la portabilité des données : le Client peut demander une copie de ses données sous une forme lisible par machine et transférable à un autre prestataire ;

4.2.8. Droit d'opposition : dans certaines circonstances (y compris lorsque les données sont traitées sur la base d'intérêts légitimes ou à des fins de marketing), le Client peut s'opposer à ce traitement ;

Si le Client souhaite exercer l'un de ses droits énumérés ci-dessus, il peut nous envoyer un e-mail à support@myclaimrefunds.com.

4.3. Les dispositions de cette partie de la Politique de Confidentialité ne limitent pas les droits de Compensair de stocker les données du Client dans le cas où cela serait requis par la loi.

Protections des données personnelles
Protections des données personnelles

4.4. Les données collectées auprès du Client peuvent être transférées et stockées vers une destination située à l'intérieur de l'Espace économique européen (« EEE »). Elles peuvent également être traitées par du personnel opérant en dehors de l'EEE qui est attiré par nous pour le traitement des demandes, le dépôt des réclamations, le traitement des paiements et la fourniture d'autres services d'assistance et qui est situé à l'intérieur de l'EEE. En soumettant ses données personnelles, le Client accepte ce transfert, ce stockage ou ce traitement. nous prendrons toutes les mesures raisonnablement nécessaires pour garantir que les données du Client sont traitées en toute sécurité et conformément à la présente Politique de confidentialité.
                                                  </pre>

                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-10">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                        <!-- end vertical nav -->
                    </div>
                </div>
                <!-- end row -->
            </div>
        </div>
        <!-- end container -->
    </section>
    <!-- Faqs end -->

    <!-- Footer start -->
    <footer class="landing-footer">
        <div class="container">

            <div class="row">
                <div class="col-lg-1 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <a href="https://fb.me/myclaimrefunds" class="btn btn-outline-success w-xs">Facebook</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <a href="javascript: void(0);" (click)="ouvrirConversationWhatsApp()"
                            class="btn btn-outline-success w-xs">Whatsapp</a>

                    </div>
                </div>

            </div>
            <br>
            <!-- end row -->
            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-4">
                        <img src="../../../../assets/images/capture.jpg" alt="" height="100">
                    </div>

                    <p class="mb-2">{{year}} © TOUS DROIT RESERVES MYCLAIMSREFUNDS.COM</p>

                </div>

            </div>
        </div>
        <!-- end container -->
    </footer>
    <!-- Footer end -->

</div>
