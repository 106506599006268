import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClaimTripDetailService } from './claim-trip-detail.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as Dropzone from 'dropzone';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import emailjs from '@emailjs/browser';
//import { AngularFireStorage } from '@angular/fire/storage';

//import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-claim-trip-detail',
  templateUrl: './claim-trip-detail.component.html',
  styleUrls: ['./claim-trip-detail.component.scss']
})
export class ClaimTripDetailComponent implements OnInit {
  currentDate: string;
  isLoading = false; // Variable pour suivre l'état de chargement
  filteredAirports: any[] = [];
  company: any[] = [];
  contry: any[] = [];
  flights: any[] = [];
  selectValueCause: string[]
  editFormForm: FormGroup;
  deuxiemeSectionVisible = false;
  troisiemeSectionVisible = false;
  areAirportsSelected = true;
  causeSelected = false;
  isStep3Valid = false;
  isStep2Valid = false;
  isForm4Valid = false;
  files: File[] = [];
  filesSelected: boolean = false;
  files2: File[] = [];
  filesSelected2: boolean = false;
  mailtoLink: SafeUrl
  apiKey: string = '8e6fb67c-8a6d-4323-8df9-aeb08cf965a3';
  @ViewChild('dropzoneElement') dropzoneElement: ElementRef;
  sanitizer: any;

  constructor(private http: HttpClient,
    //private storage: AngularFireStorage,
    private formBuilder: FormBuilder, private flightService: ClaimTripDetailService) {


    this.editFormForm = this.formBuilder.group({
      departureAirport: ['', Validators.required],
      arrivalAirport: ['', Validators.required],
      correspondances: [''],
      causeVol: [''],
      heuresDeRetard: [''],
      airline: ['', Validators.required],
      flightNumber: ['', Validators.required],
      flightDate: [null, Validators.required],
      cause: ['', Validators.required],
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      adresse: ['', Validators.required],
      ville: ['', Validators.required],
      codePostal: ['', Validators.required],
      region: [''],
      pays: ['', Validators.required],
      numeroTelephone: [''],
      numeroReservation: ['', Validators.required],
      description: ['', Validators.required],
      contacterCompagnie: [''],
    });


  } // Injectez HttpClient ici


  ngOnInit(): void {
    this.selectValueCause = ['Probléme technique', 'Jen me souvient pas', 'Mauvaise méteo', 'Gréve', 'Aucune raison donée'];

    // Écoutez les changements dans la sélection des aéroports
    this.editFormForm.get('departureAirport')?.valueChanges.subscribe(() => {
      this.updateAreAirportsSelected();
    });

    this.editFormForm.get('arrivalAirport')?.valueChanges.subscribe(() => {
      this.updateAreAirportsSelected();
    });
    // Écoutez les changements dans les champs du deuxième step
    this.editFormForm.get('airline')?.valueChanges.subscribe(() => {
      this.updateIsStep2Valid();
    });

    this.editFormForm.get('flightNumber')?.valueChanges.subscribe(() => {
      this.updateIsStep2Valid();
    });

    this.editFormForm.get('flightDate')?.valueChanges.subscribe(() => {
      this.updateIsStep2Valid();
    });


    // Écoutez les changements dans les options de sélection de la troisième section
    this.editFormForm.get('cause')?.valueChanges.subscribe(() => {
      this.updateIsStep3Valid();
    });
    this.loadAirports()
    this.loadCompanie()
    this.loadPays()
    this.loadAirports(); // Appelez la fonction pour charger les aéroports au chargement du composant
    this.flightService.getFlights().subscribe(
      (data: any) => {
        if (data && data.schedules && data.schedules.flight) {
          this.flights = data.schedules.flight;
          console.log(this.flights)
        }
      },
      (error) => {
        console.error('Error fetching flights:', error);
      }
    );

  }

  get correspondancesControlName() {
    return this.editFormForm.get('correspondances');
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
    this.filesSelected = this.files.length > 0;
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    this.filesSelected = this.files.length > 0;
  }

  onSelect2(event) {
    console.log(event);
    this.files2.push(...event.addedFiles);
    this.filesSelected2 = this.files2.length > 0;
  }

  onRemove2(event) {
    console.log(event);
    this.files2.splice(this.files2.indexOf(event), 1);
    this.filesSelected2 = this.files2.length > 0;
  }
  // Mettez à jour l'état d'activation du bouton "Suivant" du troisième step
  private updateIsStep3Valid() {
    const cause = this.editFormForm.get('cause')?.value;
    this.causeSelected = cause !== null && cause !== '';

  }




  // Mettez à jour l'état d'activation du bouton "Suivant" du deuxième step
  private updateIsStep2Valid() {
    this.isStep2Valid = this.editFormForm.get('airline')?.valid &&
      this.editFormForm.get('flightNumber')?.valid &&
      this.editFormForm.get('flightDate')?.valid;
  }

  // Mettez à jour l'état d'activation du bouton "Suivant" du premier step
  private updateAreAirportsSelected() {
    const departureAirport = this.editFormForm.get('departureAirport')?.value;
    const arrivalAirport = this.editFormForm.get('arrivalAirport')?.value;
    this.areAirportsSelected = departureAirport !== null && departureAirport !== '' &&
      arrivalAirport !== null && arrivalAirport !== '';
  }



  loadAirports() {
    this.isLoading = true; // Définissez isLoading sur true au début du chargement

    const apiUrl = `https://airlabs.co/api/v9/airports?api_key=${this.apiKey}`;

    // Envoyez la requête à l'API et mettez à jour filteredAirports avec les résultats.
    // Utilisez la fonction customSearch pour filtrer les résultats.

    this.http.get(apiUrl).subscribe((data: any) => {
      if (data && data.response) {
        console.log(data.response)
        this.filteredAirports = data.response;
        this.isLoading = false; // Définissez isLoading sur false une fois le chargement terminé
      }
    });
  }
  loadCompanie() {
    this.isLoading = true; // Définissez isLoading sur true au début du chargement

    const apiUrl = `https://airlabs.co/api/v9/airlines?api_key=${this.apiKey}`;

    // Envoyez la requête à l'API et mettez à jour filteredAirports avec les résultats.
    // Utilisez la fonction customSearch pour filtrer les résultats.

    this.http.get(apiUrl).subscribe((data: any) => {
      if (data && data.response) {
        console.log(data.response)
        this.company = data.response;
        this.isLoading = false; // Définissez isLoading sur false une fois le chargement terminé
      }
    });
  }
  loadPays() {
    this.isLoading = true; // Définissez isLoading sur true au début du chargement

    const apiUrl = `https://airlabs.co/api/v9/countries?api_key=${this.apiKey}`;

    // Envoyez la requête à l'API et mettez à jour filteredAirports avec les résultats.
    // Utilisez la fonction customSearch pour filtrer les résultats.

    this.http.get(apiUrl).subscribe((data: any) => {
      if (data && data.response) {
        console.log(data.response)
        this.contry = data.response;
        this.isLoading = false; // Définissez isLoading sur false une fois le chargement terminé
      }
    });
  }



  onClear() {
    this.filteredAirports = [];
  }

  save() {
    const formValues = this.editFormForm.value;
    const emailParams = {
      departureAirport: formValues.departureAirport,
      arrivalAirport: formValues.arrivalAirport,
      correspondences: formValues.correspondences,
      causeVol: formValues.causeVol,
      heuresDeRetard: formValues.heuresDeRetard,
      airline: formValues.airline,
      flightNumber: formValues.flightNumber,
      flightDate: formValues.flightDate,
      cause: formValues.cause,
      nom: formValues.nom,
      prenom: formValues.prenom,
      email: formValues.email,
      adresse: formValues.adresse,
      ville: formValues.ville,
      codePostal: formValues.codePostal,
      region: formValues.region,
      pays: formValues.pays,
      numeroTelephone: formValues.numeroTelephone,
      numeroReservation: formValues.numeroReservation,
      description: formValues.description,
      contacterCompagnie: formValues.contacterCompagnie
    };
    const emailParams2 = {

      email: formValues.email,
    };

    const formData = new FormData();

    for (const file of this.files) {
      formData.append('file1', file); // Utilisez un identifiant unique pour chaque fichier
    }

    for (const file of this.files2) {
      formData.append('file2', file); // Utilisez un identifiant unique pour chaque fichier
    }
    console.log(emailParams)
    // ...

    emailjs.send("service_hotrqad", "template_q3pkxrq", emailParams, "Vh-ZiziuspsXhLsoQ")
      .then(function (response) {
        console.log('E-mail envoyé avec succès', response);
        // Réinitialisez le formulaire après l'envoi, si nécessaire
        // this.editFormForm.reset();
      }, function (error) {
        console.error('Erreur lors de l\'envoi de l\'e-mail', error);
      });

      emailjs.send("service_hotrqad", "template_wejqgfb", emailParams2, "Vh-ZiziuspsXhLsoQ")
      .then(function (response) {
        console.log('E-mail envoyé avec succès au client', response);
        // Réinitialisez le formulaire après l'envoi, si nécessaire
        // this.editFormForm.reset();
      }, function (error) {
        console.error('Erreur lors de l\'envoi de l\'e-mail', error);
      });
  }




  onOptionSelected(event: any) {
    const selectedAirport = event.item;
    // Faites quelque chose avec l'aéroport sélectionné, par exemple, l'envoyer à un service.
  }

  customSearch(term: string, item: any) {
    // Utilisez cette fonction pour personnaliser la logique de recherche si nécessaire.
    return item.name.toLowerCase().includes(term.toLowerCase());
  }
}
